<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">搜索结果</div>
      <div class="topRight"></div>
    </div>
    <div class="container">
      <div class="contentBox" v-for="item in lookList" :key="item.id">
        <div class="contentTop">
          <div class="contentAbstract" @click="detailEvent(item)">
            {{ item.title }}
          </div>
          <div class="contentImg"><img :src="item.img" alt /></div>
        </div>
        <div class="contentBottom">
          <div class="contentBottomLeft">
            <div class="zd" v-if="item.top != 0">置顶</div>
            <div class="contentName">{{ item.createName }}</div>
            <div class="contentTime">{{ item.timeShow }}</div>
          </div>
          <div class="contentLikeNum">{{ item.likeNum }}点赞</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { infoPage } from "../../api/lookAt";
export default {
  data() {
    return {
      lookList: [], //列表
      lookData: {
        pageNum: 1,
        pageSize: 10,
        content: this.$route.query.value || "", //搜索
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        classify: "", //tabId
      },
    };
  },
  mounted() {
    console.log("****");
    this.gerinfoPage();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gerinfoPage() {
      infoPage(this.lookData).then((res) => {
        console.log(res, "首次数据");
        this.lookList = res.data.data.items;
        this.loading = false;
      });
    },
    // 跳转详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { item: item } });
      this.$store.commit("item", item);
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.container {
  width: 686px;
  background: #ffffff;
  margin-left: 32px;
  border-radius: 16px;
  margin-top: 26px;
  margin-bottom: 130px;
}
.contentBox {
  width: 686px;
  height: 260px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  overflow: hidden;
}
.contentTop {
  display: flex;
  margin-top: 32px;
}
.contentAbstract {
  width: 380px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  height: 146px;
  margin-left: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /*省略几行写几*/
  -webkit-box-orient: vertical;
}
.contentImg img {
  width: 210px;
  height: 140px;
  border-radius: 8px 8px 8px 8px;
  margin-left: 32px;
}
.contentBottom {
  height: 36px;
  display: flex;
  justify-content: space-between;
}
.contentBottomLeft {
  display: flex;
  align-items: center;
  margin-left: 32px;
}
.contentLikeNum {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-right: 32px;
}
.zd {
  width: 64px;
  height: 44px;
  background: rgba(69, 103, 230, 0.1);
  font-size: 24px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  color: #0957c3;
  line-height: 47px;
  text-align: center;
  margin-right: 16px;
  border-radius: 8px;
}
.contentName,
.contentTime {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
}
.contentName {
  margin-left: 0px;
}
.likeNum {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
  text-align: right;
  margin-top: 24px;
}
</style>
